// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Tooltip, Popover, Progress } from '@mantine/core';
import Cards from '../../components/general/Cards';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { HiOutlinePrinter } from 'react-icons/hi';
import Swal from 'sweetalert2';
import ModalPatroliDetail from '../../components/patroli/ModalPatroliDetail';
import moment from 'moment';
import FilterKategori from '../../components/general/FilterKategori';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import FilterWithDate from '../../components/general/FilterWithDate';

export default function Patroli({ isAdmin }) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [datas, setDatas] = useState([]);
  const [data, setData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [patroliDetailKode, setPatroliDetailKode] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [type, setType] = useState(queryParams.get('tipe') || '');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Date
  const { perusahaanKode, startDateAdmin, endDateAdmin } = useSelector((state) => state.perusahaan);
  const [date, setDate] = useState({
    startDate: startDateAdmin || moment().format('YYYY-MM-DD'),
    endDate: endDateAdmin || moment().format('YYYY-MM-DD')
  });
  const [kategori, setKategori] = useState(queryParams.get('kategori') || '');

  useEffect(() => {
    if (location.pathname === '/patroli') {
      const queryParams = new URLSearchParams(location.search);
      setDate({
        startDate: queryParams.get('startDate'),
        endDate: queryParams.get('endDate')
      });
      setKategori(queryParams.get('kategori') || '');
      setType(queryParams.get('tipe') || '');
    } else {
      setDate({
        startDate: startDateAdmin,
        endDate: endDateAdmin
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateAdmin, endDateAdmin]);

  // Column Data Table
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialColumnData = [
    {
      title: 'Action',
      accessor: 'satpampatrolikode',
      width: 100,
      render: ({ satpampatrolikode, satpampatrolikemajuan, satpampatrolisatpamkode }) => (
        <div className="flex items-center gap-1 h-32">
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              {parseInt(satpampatrolikemajuan) <= 99 && (
                <div size="sm" className="popOverItem" onClick={() => handleSendNotification(satpampatrolisatpamkode)}>
                  <i className="fa-solid fa-bell"></i> Push Notif
                </div>
              )}
              <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(satpampatrolikode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/patroli-printperpat/${satpampatrolikode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-print"></i> Print
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleDelete(satpampatrolikode)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Progress',
      accessor: 'satpampatrolikemajuan',
      width: 150,
      render: ({ satpampatrolikemajuan }) => (
        <>
          {!isNaN(satpampatrolikemajuan) ? (
            <div className="text-center mt-5">
              {parseInt(satpampatrolikemajuan)} %
              <Progress
                className="mb-2"
                color={parseInt(satpampatrolikemajuan) <= 30 ? 'yellow' : parseInt(satpampatrolikemajuan) <= 70 ? 'teal' : 'blue'}
                value={parseInt(satpampatrolikemajuan)}
                size="md"
                radius="md"
              />
            </div>
          ) : (
            <div className="flex justify-center items-center mx-auto">0 %</div>
          )}
        </>
      )
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'POS',
      accessor: 'satpamposnama',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Tanggal Mulai',
      accessor: 'satpampatrolimulaitanggal',
      width: 160,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpampatrolimulaitanggal }) => <>{satpampatrolimulaitanggal !== '' && moment(satpampatrolimulaitanggal).format('DD MMM YYYY HH:mm')}</>
    },
    {
      title: 'Tanggal Selesai',
      accessor: 'satpampatroliselesaitanggal',
      width: 160,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpampatroliselesaitanggal }) => <>{satpampatroliselesaitanggal !== '' && moment(satpampatroliselesaitanggal).format('DD MMM YYYY HH:mm')}</>
    }
  ];

  const [columnData, setColumnData] = useState(initialColumnData);

  // Get data
  useEffect(() => {
    const formData = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpampatrolijenis: kategori
    };

    if (perusahaanKode) {
      formData.satpamperusahaankode = perusahaanKode;
    }

    if (kategori !== '') {
      kategori === 'Patroli Jalan Kaki' ? setColumnData(initialColumnData) : setColumnData((prev) => [...prev]);
      const getData = async (formData) => {
        await api.patroli(formData).then((res) => {
          setData(res.data.data);
          setDatas(res.data);
        });
      };

      getData(formData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, kategori, perusahaanKode]);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(({ satpamnamalengkap, satpamposnama, satpampatrolimulaitanggal, satpampatroliselesaitanggal }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamnamalengkap} ${satpamposnama} ${satpampatrolimulaitanggal} ${satpampatroliselesaitanggal} `.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        })
      );
    }
  }, [data, debouncedQuery]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (location.pathname !== '/laporan-semua') {
      params.set('startDate', date.startDate);
      params.set('endDate', date.endDate);
      if (kategori) params.set('kategori', kategori);
      if (type) params.set('tipe', type);
      navigate(
        {
          pathname: location.pathname,
          search: params.toString()
        },
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, kategori]);

  const handleOpenModalDetail = (patrolikode) => {
    setPatroliDetailKode(patrolikode);
    setOpenModalDetail(true);
  };

  const handleSendNotification = async (patrolikode) => {
    await api.patroliSendNotif(patrolikode).then((res) => {
      Swal.fire('Good job!', res.data.message, 'success');
    });
  };

  const handleDelete = (kode) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpampatrolijenis: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    Swal.fire({
      text: 'Do you want to delete this patroli?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .patroliDelete(kode)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              if (kategori !== '') {
                const getData = async (formDate) => {
                  await api.patroli(formDate).then((res) => {
                    setData(res.data.data);
                    setDatas(res.data);
                  });
                };

                getData(formDate);
              }
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };
  const dataFilter = [
    {
      key: 'today',
      value: 'Today'
    },
    {
      key: 'yesterday',
      value: 'Yesterday'
    },
    {
      key: 'this_week',
      value: 'This week'
    },
    {
      key: 'this_month',
      value: 'This month'
    },
    {
      key: 'custom',
      value: 'Custom Range'
    }
  ];
  return (
    <div className={`relative w-full min-h-screen p-5 ${isAdmin ? 'mt-1' : '-mt-10'}`}>
      {/* Cards */}
      <Cards
        title={`Statistik ${kategori}`}
        desc={kategori === '' ? 'Silahkan pilih kategori terlebih dahulu' : `Berikut ini adalah statistik ${kategori.toLowerCase()}`}
        card1={{ title: 'Selesai', value: datas?.patroli_selesai || 0 }}
        card2={{ title: 'Belum Selesai', value: datas?.patroli_belum_selesai || 0 }}
        card3={{
          title: 'Total Patroli',
          value: datas?.total_patroli || 0
        }}
      />

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            {!isAdmin && <FilterWithDate date={date} setDate={setDate} tipeLaporan={dataFilter} type={type} setType={setType} />}
            <FilterKategori kategori={kategori} setKategori={setKategori} page="patroli" />
          </div>
          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-fit">
              <Tooltip label="Tambah patroli">
                <Link to="/patroli/create" state={{ startdate: date.startDate, enddate: date.endDate, satpampatrolijenis: kategori }}>
                  <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                    <AiOutlineFileAdd size={24} />
                  </div>
                </Link>
              </Tooltip>
              <Link to={`/patroli-print`} state={{ data: data, date: date }}>
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <HiOutlinePrinter size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Table Component */}
        {data.length === 0 ? (
          <div className="text-center p-5">
            <div className="mt-10">Tidak ada data patroli {kategori === 'jalan_kaki' ? 'jalan kaki' : 'berkendara'}.</div>
            <div className="mb-72">Silahkan memilih rentang tanggal dan kategori lainnya!</div>
          </div>
        ) : (
          <div className="p-5">
            <Table data={filterData} columnData={columnData} setQuery={setQuery} />
          </div>
        )}
      </div>

      {/* Modal Detail Patroli Jalan Kaki */}
      <ModalPatroliDetail kode={patroliDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />
    </div>
  );
}
