export default function Headerlaporan() {
  return (
    <div className="min-w-fit px-5 py-2 flex items-center gap-2 bg-gray-400 whitespace-nowrap">
      <img src={require('../../images/logo.png')} alt="" className="w-[50px] h-[50px]" />
      <div className="flex flex-col items-center">
        <img src={require('../../images/tulisan_putih.png')} alt="" className="h-[40px] w-auto" />
        <div className="text-white text-[18px] font-bold">{process.env.REACT_APP_NAME}</div>
      </div>
    </div>
  );
}
